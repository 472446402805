<template>
  <material-card v-bind="$attrs" class="v-card--material-chart" full-header titleRounded="xl">
    <template #heading>
      <div class="pa-4">
        <!-- <chartist
          :data="data"
          :event-handlers="eventHandlers"
          :options="options"
          :ratio="ratio"
          :responsive-options="responsiveOptions"
          :type="type"
          style="max-height: 150px;"
        /> -->
        <v-chart
          ref="chart"
          :option="option"
          :auto-resize="true"
          style="width: 100%;"
          :style="{height:$vuetify.breakpoint.xs?'20vh':($vuetify.breakpoint.sm?'35vh':'28vh')}"          
        />        
      </div>
    </template>

    <template #subtitle>
      <slot name="subtitle" />
    </template>

    <slot />

    <template #actions>
      <slot name="actions" />
    </template>
  </material-card>
</template>

<script>
import MaterialCard from "@/components/MaterialCard.vue";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart, LineChart, BarChart, ScatterChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
  LineChart,
  BarChart,
  ScatterChart,
]);
export default {
  name: "MaterialChartCard",
  components: {
    VChart,
    MaterialCard,
  },
  props: {
    option: {
      type: Object,
      default: () => ({}),
    },           
  },
  provide: {
    [THEME_KEY]: "light",
  },
 
  methods: {
    resize() {      
      if (this.$refs && this.$refs.chart) {    

          this.$refs.chart.resize({
            animation:{
              duration: 500,
              //easing:'bounceOut'
            }
          })

    }
    },
  },
};
</script>

<style lang="sass">

.v-card--material-chart
  p
    color: #999

  .v-card--material__sheet
    
    width: 100%

    .ct-label
      color: rgba(255, 255, 255, 1)
      opacity: .7
      font-size: 0.875rem
      font-weight: 300

    .ct-grid
      stroke: hsla(0,0%,100%,.2)

    .ct-series-a .ct-point,
    .ct-series-a .ct-line,
    .ct-series-a .ct-bar,
    .ct-series-a .ct-slice-donut
        stroke: rgba(255,255,255,.8)

    .ct-series-a .ct-slice-pie,
    .ct-series-a .ct-area
        fill: rgba(255,255,255,.4)
</style>
