import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import ProfitView from '../views/ProfitView.vue'
import CalendarView from '../views/CalendarView.vue'
import SaleView from '../views/SaleView.vue'
import ActivityView from '../views/ActivityView.vue'
import ProfitShopView from '../views/ProfitShopView.vue'
import ReturnView from '../views/ReturnView.vue'
import CompanyProfitView from '../views/CompanyProfitView.vue'
import DeviceTestView from '../views/DeviceTestView.vue'
import DeviceSwitchView from '../views/DeviceSwitchView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {      
      title: 'MC Business Intelligence',     
    },
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {      
      title: 'MC Business Intelligence',     
    },
  },
  {
    path: '/profit',
    name: 'profit',
    component: ProfitView,
    meta: {      
      title: 'MC Business Intelligence',     
    },
  },
  {
    path: '/profit_shop',
    name: 'profit_shop',
    component: ProfitShopView,
    meta: {      
      title: 'MC Business Intelligence',     
    },
  },
  {
    path: '/activity',
    name: 'activity',
    component: ActivityView,
    meta: {      
      title: 'MC Business Intelligence',     
    },
  },

  {
    path: '/company_profit',
    name: 'company_profit',
    component: CompanyProfitView,
    meta: {      
      title: 'MC Business Intelligence',     
    },
  },

  
  {
    path: '/return',
    name: 'return',
    component: ReturnView,
    meta: {      
      title: 'MC Business Intelligence',     
    },
  },
  
  {
    path: '/sale',
    name: 'sale',
    component: SaleView,
    meta: {      
      title: 'MC Business Intelligence',     
    },
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: CalendarView,
    meta: {      
      title: 'MC Business Intelligence',     
    },
  },
  {
    path: '/device_test',
    name: 'device_test',
    component: DeviceTestView,
    meta: {      
      title: 'DeviceTest',     
    },
  },
  {
    path: '/device_switch',
    name: 'device_switch',
    component: DeviceSwitchView,
    meta: {      
      title: 'DeviceSwitch',     
    },
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
