<template>
  <v-app>
    <TopBar value="公司利润表" :hasSearch="true" @openSearch="openSearch" />
    <SearchByCompany :show.sync="showSearch" @search="search" />
    <v-main>
      <v-container fluid>
        <v-card
          elevation="5"
         
          class="mb-4"
        >
          <v-card-title
            class="text-truncate text-center"
            style="display: block"
          >
          公司利润表
          </v-card-title>
          <v-data-table
            disable-pagination
            hide-default-footer
            :headers="headers"
            :items="report.companys"
            item-key="id"
            :search="report.keyword"
            :header-props="headerProps"
            sort-by="amount_total"
            :sort-desc="true"
          >
            <template v-slot:top>
              <v-row>
                <v-col cols="9" lg="11">
                  <v-text-field
                    v-model="report.keyword"
                    label="筛选"
                    class="mx-4"
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="3"
                  lg="1"
                  class="d-flex justify-end align-center pr-6"
                >
                  <v-btn class="primary" rounded @click="exportExcel()">
                    <v-icon left> file_download </v-icon>导出</v-btn
                  >
                </v-col></v-row
              >
            </template>

            <template slot="body.append">
              <tr
                :class="{ 'v-data-table__mobile-table-row': isMobile }"
                class="primary--text"
              >
                <th :class="{ 'v-data-table__mobile-row': isMobile }">合计:</th> 
               
                             
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    活动收入:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(report.total) }}
                </th>
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    活动成本:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(report.cost) }}
                </th>
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    活动费用:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(report.out_total) }}
                </th>
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    活动毛利:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(report.profit) }}
                </th>
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    仪器成本:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(report.device_total) }}
                </th>
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    管理费:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(report.manage_total) }}
                </th>
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    折旧:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(report.depreciation_total) }}
                </th>
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    摊销类费用:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(report.amortization_total) }}
                </th>
                
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    工资:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(report.salary_total) }}
                </th>
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    提成奖金:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(report.bonus_total) }}
                </th>                
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    社保公积金:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(report.social_total) }}
                </th>
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    差旅费:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(report.travel_total) }}
                </th>
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    其他费用:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(report.other_total) }}
                </th>
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    财务费用:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(report.finance_total) }}
                </th>
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    税金及附加:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(report.tax_total) }}
                </th>
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    成本费用合计:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(report.all_cost) }}
                </th>
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    公司利润:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(report.all_profit) }}
                </th>
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    利润率:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(report.profit_scale) }}
                </th>
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    货币资金:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(report.monetary_fund) }}
                </th>

              </tr>

            </template>

            

            <template
              v-for="header in headers.filter((header) =>
                header.hasOwnProperty('formatter')
              )"
              v-slot:[`item.${header.value}`]="{ header, value }"
            >
              {{ header.formatter(value) }}
            </template>
          </v-data-table>
        </v-card>
      </v-container>
      <v-overlay :value="loading">
        <v-progress-circular
          indeterminate
          color="primary"
          style="
            position: absolute;
            top: 20%;
            left: 50%;
            transform: translate(-50%, -50%);
          "
          v-if="loading"
          size="70"
          :width="3"
        ></v-progress-circular>
      </v-overlay>
    </v-main>
    <BackToTop />
  </v-app>
</template>
<script>
import { company_promit } from "@/api/search";
export default {
  name: "CompanyProfitView",
  data() {
    return {
      showSearch: true,
      loading: false,
      keyword: "",
      report: [],
      headerProps: {
        sortByText: "排序",
      },
      headers: [
        { text: "公司名称", value: "name" },                      
        { text: "活动收入", value: "total", formatter: this.formatNum },
        { text: "活动成本", value: "cost", formatter: this.formatNum },        
        { text: "活动费用", value: "out_total", formatter: this.formatNum },
        { text: "活动毛利", value: "profit", formatter: this.formatNum },
        { text: "仪器成本", value: "device_total", formatter: this.formatNum },
        { text: "管理费", value: "manage_total", formatter: this.formatNum },
        { text: "折旧", value: "depreciation_total", formatter: this.formatNum },
        { text: "摊销类费用", value: "amortization_total", formatter: this.formatNum },
        
        { text: "工资", value: "salary_total", formatter: this.formatNum },
        { text: "提成奖金", value: "bonus_total", formatter: this.formatNum },        
        { text: "社保公积金", value: "social_total", formatter: this.formatNum },
        { text: "差旅费", value: "travel_total", formatter: this.formatNum },
        { text: "其他费用", value: "other_total", formatter: this.formatNum },
        { text: "财务费用", value: "finance_total", formatter: this.formatNum },
        { text: "税金及附加", value: "tax_total", formatter: this.formatNum },
        { text: "成本费用合计", value: "all_cost", formatter: this.formatNum },
        { text: "公司利润", value: "all_profit", formatter: this.formatNum },   
        { text: "利润率", value: "profit_scale", formatter: this.formatNum },  
        { text: "货币资金", value: "monetary_fund", formatter: this.formatNum },       
      ],
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },
  },

  methods: {
    openSearch() {
      this.showSearch = !this.showSearch;
    },
    async search(condition) {
      this.loading = true;
      this.report = await company_promit(condition);
      this.loading = false;
      console.log(this.report);
    },
    exportExcel() {
      this.loading = true;
      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = [
          "公司名称",
          "活动收入",
          "活动成本",       
          "活动费用",
          "活动毛利",          
          "仪器成本",
          "管理费",
          "折旧",
          "摊销类费用",
          
          "工资",
          "提成奖金",
          "社保公积金",
          "与行程无关的差旅费",
          "其他费用",
          "财务费用",
          "税金及附加",
          "成本费用合计",
          "公司利润",
          "利润率",
          "货币资金"
        ];
        const filterVal = [
          "name",
          "total",
          "cost",         
          "out_total",
          "profit",          
          "device_total",
          "manage_total",
          "depreciation_total",
          "amortization_total",
          
          "salary_total",
          "bonus_total",
          "social_total", 
          "travel_total",
          "other_total",
          "finance_total",
          "tax_total",
          "all_cost",
          "all_profit",
          "profit_scale",
          "monetary_fund"
        ];
        const list = this.report.companys;
        const data = this.formatJson(filterVal, list);
        data.push([
          "合计",
          this.report["total"],
          this.report["cost"],
          this.report["out_total"],
          this.report["profit"],
          this.report["device_total"],
          this.report["manage_total"],
          this.report["depreciation_total"],
          this.report["amortization_total"],
          
          this.report["salary_total"],
          this.report["bonus_total"],
          this.report["social_total"],
          this.report["travel_total"],
          this.report["other_total"],
          this.report["finance_total"],
          this.report["tax_total"],
          this.report["all_cost"],
          this.report["all_profit"],
          this.report["profit_scale"],
          this.report["monetary_fund"]          

        ]);

        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "公司利润表",
          autoWidth: true,
          bookType: "xlsx",
        });
        this.loading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
    formatNum(value) {
      if (value) {
        return value.toLocaleString();
      } else {
        return 0;
      }
    },
  },
};
</script>
