import request from '@/utils/request'

export  function login(data) {
  return request({
    url: '/authorizations',
    method: 'post',
    data
  })
}

export  function logout() {
  return request({
    url: '/logout',
    method: 'post',
  })
}

export  function userinfo() {
  return request({
    url: '/userinfo',
    method: 'get',
  })
}

export function menu() {
  return request({
    url: "/menu",
    method: "get",
  });
}