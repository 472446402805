<template>
  <div>
    <v-navigation-drawer v-model="drawer" fixed app  class="grey lighten-5 font-weight-regular">
      <v-toolbar flat dark color="primary" elevation="2">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>导航</v-toolbar-title>
          
      </v-toolbar>
      <v-list nav shaped >
        <v-list-item  to="/" active-class="primary--text">
        <v-list-item-icon>
          <v-icon>home</v-icon>
        </v-list-item-icon>

        <v-list-item-title >首页</v-list-item-title>
      </v-list-item>
        <v-list-group
          v-for="item in menu"
          :key="item.name"
          v-model="item.active"
          :prepend-icon="item.icon"          
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="child in item.reports"
            :key="child.name"
            :to="child.path"            
            ><v-list-item-icon>
              <v-icon>arrow_right</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title  v-text="child.name"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
      <template v-slot:append>
        
        <div class="pa-2">
          <v-btn block class="red darken-4 white--text" @click="logout" rounded>
            <v-icon left>
            logout
      </v-icon>
            注销退出
          </v-btn>
        </div>
        <div :style="{height:screenHeight-innerHeight+'px'}" style="max-height:15vh;" v-if="$vuetify.breakpoint.smAndDown"></div>
      </template>
    </v-navigation-drawer>
    <v-app-bar color="deep-purple" dark app>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer">
        <v-icon>
      {{ drawer ? 'more_vert' : 'format_list_bulleted' }}
    </v-icon>
      </v-app-bar-nav-icon>
      
      <v-toolbar-title class="font-weight-regular">{{ value }}</v-toolbar-title>
      <v-spacer></v-spacer>
      
      <slot name="calendar" />
      
      
      <v-btn icon @click="search" v-if="hasSearch">
        <v-icon>search</v-icon>
      </v-btn>

      <v-btn icon to="/" v-if="!isHome">
        <v-icon>home</v-icon>
      </v-btn>

      

    </v-app-bar>
  </div>
</template>
<script>
import {  logout,menu } from "@/api/user";
export default {
  name: "TopBar",
  props: {
    value: {
      type: String,
      default: "",
    },
    hasSearch: {
      type: Boolean,
      default: false,
    },
    isHome: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    
    menu:[],
    drawer: false,
    userinfo: {},
    innerHeight:0,
    screenHeight:0,
    
  }),
  created() {
    this.getMenu();
    this.innerHeight=window.innerHeight
    this.screenHeight=window.screen.height
  },
  mounted() {  
      window.addEventListener('resize', () => {
        this.innerHeight=window.innerHeight
        this.screenHeight=window.screen.height
      })    

  },
  methods: {
    async getMenu() {      
      this.menu=await menu()
    },
    search() {
      this.$emit("openSearch");
    },
    async logout() {
    await logout();
    this.$router.replace("/login");
  }
  },
  
};
</script>
