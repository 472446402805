<template>
  <v-app>
    <TopBar value="活动分析表" :hasSearch="true" @openSearch="openSearch" />
    <SearchByCompany :show.sync="showSearch" @search="search" />
    <v-main>
      <v-container fluid>
        <v-card
          elevation="5"
          v-for="(company, index) in report"
          :key="index"
          class="mb-4"
        >
          <v-card-title
            class="text-truncate text-center"
            style="display: block"
          >
            {{ company.name }}活动分析表
          </v-card-title>
          <v-data-table
            disable-pagination
            hide-default-footer
            :headers="headers"
            :items="company.batchs"
            item-key="id"
            :search="company.keyword"
            :header-props="headerProps"
            sort-by="amount_total"
            :sort-desc="true"
          >
            <template v-slot:top>
              <v-row>
                <v-col cols="9" lg="11">
                  <v-text-field
                    v-model="company.keyword"
                    label="筛选"
                    class="mx-4"
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="3"
                  lg="1"
                  class="d-flex justify-end align-center pr-6"
                >
                  <v-btn class="primary" rounded @click="exportExcel(index)">
                    <v-icon left> file_download </v-icon>导出</v-btn
                  >
                </v-col></v-row
              >
            </template>

            <template slot="body.append">
              <tr
                :class="{ 'v-data-table__mobile-table-row': isMobile }"
                class="primary--text"
              >
                <th :class="{ 'v-data-table__mobile-row': isMobile }">合计:</th> 
                <th
                  :class="{ 'v-data-table__mobile-row': isMobile }"
                  v-if="!isMobile"
                ></th>
                <th
                  :class="{ 'v-data-table__mobile-row': isMobile }"
                  v-if="!isMobile"
                ></th>              
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    订单收入:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(company.p_income) }}
                </th>
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    升单收入:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(company.upgrade) }}
                </th>               
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    销售退货:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(company.return) }}
                </th>
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    收入合计:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(company.total) }}
                </th>
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    产品成本:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(company.cost) }}
                </th>
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    店家奖励:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(company.sale_reward) }}
                </th>
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    成交奖励:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(company.feiy_reword) }}
                </th>
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    差旅费报销:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(company.travel_total) }}
                </th>
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    其他费用报销:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(company.other_total) }}
                </th>
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    员工提成:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(company.cut_total) }}
                </th>
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    活动费用合计:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(company.out_total) }}
                </th> 
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    活动毛利:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(company.profit) }}
                </th> 
              </tr>

            </template>

            <template
              v-for="header in headers.filter((header) =>
                header.hasOwnProperty('formatter')
              )"
              v-slot:[`item.${header.value}`]="{ header, value }"
            >
              {{ header.formatter(value) }}
            </template>
          </v-data-table>
        </v-card>
      </v-container>
      <v-overlay :value="loading">
        <v-progress-circular
          indeterminate
          color="primary"
          style="
            position: absolute;
            top: 20%;
            left: 50%;
            transform: translate(-50%, -50%);
          "
          v-if="loading"
          size="70"
          :width="3"
        ></v-progress-circular>
      </v-overlay>
    </v-main>
    <BackToTop />
  </v-app>
</template>
<script>
import { activity } from "@/api/search";
export default {
  name: "ActivityView",
  data() {
    return {
      showSearch: true,
      loading: false,
      keyword: "",
      report: [],
      headerProps: {
        sortByText: "排序",
      },
      headers: [
        { text: "行程", value: "name" },
        { text: "开始日期", value: "date" },
        { text: "结束日期", value: "date_end" },               
        { text: "订单收入", value: "p_income", formatter: this.formatNum },
        { text: "升单收入", value: "kaxiang", formatter: this.formatNum },        
        { text: "销售退货", value: "return", formatter: this.formatNum },
        { text: "活动收入合计", value: "amount_total", formatter: this.formatNum },
        { text: "产品成本", value: "cost", formatter: this.formatNum },
        { text: "店家奖励", value: "sale_reward", formatter: this.formatNum },
        { text: "成交奖励", value: "feiy_reword", formatter: this.formatNum },
        { text: "差旅费报销", value: "travel_total", formatter: this.formatNum },
        { text: "其他费用报销", value: "other_total", formatter: this.formatNum },
        { text: "员工提成", value: "cut_total", formatter: this.formatNum },
        { text: "活动费用合计", value: "out_total", formatter: this.formatNum },
        { text: "活动毛利", value: "profit", formatter: this.formatNum },        
      ],
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },
  },

  methods: {
    openSearch() {
      this.showSearch = !this.showSearch;
    },
    async search(condition) {
      this.loading = true;
      this.report = await activity(condition);
      this.loading = false;
      console.log(this.report);
    },
    exportExcel(index) {
      this.loading = true;
      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = [
          "行程",
          "开始日期",
          "结束日期",       
          "订单收入",
          "升单收入",          
          "销售退货",
          "活动收入合计",
          "产品成本",
          "店家奖励",
          "成交奖励",
          "差旅费报销",
          "其他费用报销",
          "员工提成",
          "活动费用合计",
          "活动毛利"
        ];
        const filterVal = [
          "name",
          "date",
          "date_end",         
          "p_income",
          "upgrade",          
          "return",
          "amount_total",
          "cost",
          "sale_reward",
          "feiy_reword",
          "travel_total",
          "other_total",
          "cut_total", 
          "out_total",
          "profit"
        ];
        const list = this.report[index]["batchs"];
        const data = this.formatJson(filterVal, list);
        data.push([
          "合计",
          "",
          "",          
          this.report[index]["p_income"],
          this.report[index]["upgrade"],
          this.report[index]["return"],
          this.report[index]["total"],          
          this.report[index]["cost"],
          this.report[index]["sale_reward"],
          this.report[index]["feiy_reword"],
          this.report[index]["travel_total"],
          this.report[index]["other_total"],
          this.report[index]["cut_total"],
          this.report[index]["out_total"],
          this.report[index]["profit"],
        ]);

        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.report[index]["name"] + "活动分析表",
          autoWidth: true,
          bookType: "xlsx",
        });
        this.loading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
    formatNum(value) {
      if (value) {
        return value.toLocaleString();
      } else {
        return 0;
      }
    },
  },
};
</script>
