<template>
  <v-app>
    <TopBar value="美成商业智能" isHome>
      <template slot="calendar">
        

        <v-menu
        v-model="showCalendar"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    :offset-y="$vuetify.breakpoint.xs ? true : true"
    :offset-x="$vuetify.breakpoint.xs ? true : true"
    :left="$vuetify.breakpoint.xs ? true : true"
    min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-expand-transition>
          <v-btn rounded small color="deep-purple lighten-1"  v-bind="attrs"
            v-on="on" v-show="today">
            <v-icon left class="mr-1">
              history
      </v-icon>
            {{today}}
          </v-btn>
          </v-expand-transition>
        </template>
        <v-date-picker
          v-model="today"
          @input="showCalendar = false"
          show-adjacent-months
          locale="zh-cn"
          @change="change"
        ></v-date-picker>
      </v-menu>


      </template>
    </TopBar>
    <v-main>
      <v-container id="dashboard-view" fluid tag="section" class="mt-5">
        <v-row>
          <v-col cols="12">
            <v-row v-if="!homeLoading">
              <v-col
                v-for="(chart, i) in charts"
                :key="`chart-${i}`"
                cols="12"
                md="4"
                lg="4"
              >
                <material-chart-card
                  :option="chart.option"
                  :color="chart.color"
                  :title="chart.title"
                  ref="chart"
                >
                  <template #actions>
                    <v-icon class="mr-1" small>navigate_next</v-icon>

                    <span
                      class="text-caption grey--text font-weight-light"
                      v-text="chart.subtitle"
                    />
                    <!-- <v-btn text small color="primary"  @click="goSale([1,2,3],week,today)" >{{chart.actionText}}</v-btn>  -->
                  </template>
                </material-chart-card>
              </v-col>


              <v-col
                v-for="({ actionIcon, actionText,start, ...attrs }, i) in stats1"
                :key="i"
                cols="12"
                md="6"
                lg="6"
              >
                <material-stat-card-three v-bind="attrs" titleRounded="lg" iconSmall fullHeader>
                  <template #actions>
                    <v-icon class="mr-2" small v-text="actionIcon" />
                    <v-btn text v-text="actionText" color="primary" @click="goSale([1,2,4,5,6],start,today)" /> 
                  </template>
                </material-stat-card-three>
              </v-col>

              <v-col
                v-for="({ actionIcon, actionText,companyTypes, ...attrs }, i) in companyTypeStat"
                :key="i"
                cols="12"
                md="4"
                lg="4"
              >
                <material-stat-card-three v-bind="attrs" titleRounded="lg" iconSmall fullHeader>
                  <template #actions>
                    <v-icon class="mr-2" small v-text="actionIcon" />
                    <v-btn text v-text="actionText" color="primary" @click="goSale(companyTypes,today,today)" />                    
                  </template>
                </material-stat-card-three>
              </v-col>

              <!-- <v-col
                v-for="({ actionIcon, actionText, ...attrs }, i) in stats"
                :key="i"
                cols="6"
                md="4"
                lg="4"
              >
                <material-stat-card v-bind="attrs" titleRounded="lg">
                  <template #actions>
                    <v-icon class="mr-2" small v-text="actionIcon" />
                    <div class="text-truncate">
                      {{ actionText }}
                    </div>
                  </template>
                </material-stat-card>
              </v-col> -->

              

              


            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <v-overlay :value="homeLoading" opacity="0.2">
        <v-progress-circular
          indeterminate
          color="primary"
          style="
            position: absolute;
            top: 20%;
            left: 50%;
            transform: translate(-50%, -50%);
          "
          v-if="homeLoading"
          size="70"
          :width="3"
        ></v-progress-circular>
      </v-overlay>
    </v-main>
  </v-app>
</template>

<script>
import { home } from "@/api/search";
export default {
  name: "HelloWorld",

  data() {
    return {
      showCalendar: false,      
      homeLoading: true,
      today:null,
      week:'',
      month:'',
      charts: [
        {
          color: "primary",
          title: "近一周成单量",
          subtitle: "Order amount in recent week",
          time: "更新于2022年9月1日",
          actionIcon: "navigate_next",
          actionText: "查看详情",
          option: {
            color: ["rgba(255, 255, 255, 0.8)"],
            grid: {
              top: 20,
              bottom: 20,
              left: 10,
              right: 10,
            },
            xAxis: {
              type: "category",
              data: [],
              axisLine: {
                lineStyle: {
                  color: "rgba(255, 255, 255, 0.5)",
                  type: "dashed",
                },
              },
            },
            yAxis: {
              type: "value",
              axisLine: {
                lineStyle: {
                  color: "rgba(255, 255, 255, 0.0)",
                },
              },
              splitLine: {
                lineStyle: {
                  color: "rgba(255, 255, 255, 0.2)",
                  type: "dashed",
                },
              },
            },
            series: [
              {
                data: [],
                type: "bar",
                showBackground: true,
                backgroundStyle: {
                  color: "rgba(180, 180, 180, 0.2)",
                },

                label: {
                  show: true,
                  position: "top",
                  textStyle: {
                    color: "rgba(255, 255, 255, 0.8)",
                    fontSize: 12,
                  },
                },
              },
            ],
          },
        },
        {
          color: "cyan",
          title: "近一周成交金额",
          subtitle: "Transaction amount in recent week",
          time: "更新于2022-09-01",
          option: {
            color: ["rgba(255, 255, 255, 0.5)"],
            grid: {
              top: 30,
              bottom: 20,
              left: 10,
              right: 10,
            },
            xAxis: {
              type: "category",
              data: [],
              axisLine: {
                lineStyle: {
                  color: "rgba(255, 255, 255, 0.5)",
                  type: "dashed",
                },
              },
            },
            yAxis: {
              type: "value",
              axisLine: {
                lineStyle: {
                  color: "rgba(255, 255, 255, 0)",
                },
              },
              splitLine: {
                lineStyle: {
                  color: "rgba(255, 255, 255, 0.3)",
                  type: "dashed",
                },
              },
            },
            series: [
              {
                data: [],
                type: "line",
                lineStyle: {
                  color: "rgba(255, 255, 255, 0.3)",
                  width: 5,
                },
                symbolSize: 9,
                smooth: true,
                label: {
                  show: true,
                  rotate: 30,
                  textStyle: {
                    color: "rgba(255, 255, 255, 0.8)",
                    fontSize: 11,
                  },
                  formatter:function(p){
                  return (p.value /10000).toFixed(2) + 'W';
                  
                },
                  
                },
              },
            ],
          },
        },
        {
          color: "pink darken-1",
          title: "近一周操作人次",
          subtitle: "Operation amount in recent week",
          time: "更新于2022-09-01",
          option: {
            color: ["rgba(255, 255, 255, 0.5)"],
            grid: {
              top: 25,
              bottom: 20,
              left: 10,
              right: 10,
            },
            xAxis: {
              type: "category",
              data: [],
              axisLine: {
                lineStyle: {
                  color: "rgba(255, 255, 255, 0.5)",
                  type: "dashed",
                },
              },
            },
            yAxis: {
              type: "value",
              axisLine: {
                lineStyle: {
                  color: "rgba(255, 255, 255, 0)",
                },
              },
              splitLine: {
                lineStyle: {
                  color: "rgba(255, 255, 255, 0.3)",
                  type: "dashed",
                },
              },
            },
            series: [
              {
                data: [],
                type: "line",
                lineStyle: {
                  color: "rgba(255, 255, 255, 0.3)",
                  width: 5,
                },
                symbolSize: 9,
                smooth: false,
                label: {
                  show: true,
                  rotate: 30,
                  textStyle: {
                    color: "rgba(255, 255, 255, 0.8)",
                    fontSize: 12,
                  },
                },
              },
            ],
          },
        },
      ],

      stats: [
        {
          actionIcon: "navigate_next",
          actionText: "Daily turnover",
          color: "#FD9A13",
          icon: "currency_yen",
          title: "日成交额",
          value: "¥0",          
        },

        {
          actionIcon: "navigate_next",
          actionText: "Daily customer",
          color: "primary",
          icon: "post_add",
          title: "日客户数",
          value: "0",
        },
        {
          actionIcon: "navigate_next",
          actionText: "Daily operation",
          color: "success",
          icon: "self_improvement",
          title: "日操作人次",
          value: "0",
        },
        {
          actionIcon: "navigate_next",
          actionText: "Monthly turnover",
          color: "pink",
          icon: "currency_yen",
          title: "月成交额",
          value: "¥0",
        },

        {
          actionIcon: "navigate_next",
          actionText: "Monthly customer",
          color: "cyan",
          icon: "post_add",
          title: "月客户数",
          value: "0",
        },
        {
          actionIcon: "navigate_next",
          actionText: "Monthly operation",
          color: "blue-grey",
          icon: "self_improvement",
          title: "月操作人次",
          value: "0",
        },
      ],

      stats1: [
        {
          actionIcon: "navigate_next",
          actionText: "查看详情",          
          color: "success",
          icon: "today",
          heading: "日数据",
          items: [
            {
              title: "日成交额",
              value: "¥0",
            },
            {
              title: "日客户数",
              value: "0",
            },
            {
              title: "日操作人次",
              value: "0",
            },          
          ],          
        },

        {
          actionIcon: "navigate_next",
          actionText: "查看详情",
          color: "primary",
          icon: "calendar_month",
          heading: "月数据",
          items: [
            {
              title: "月成交额",
              value: "0",
            },
            {
              title: "月客户数",
              value: "0",
            },
            {
              title: "月操作人次",
              value: "0",
            },          
          ],
        },
        
      ],

      companyTypeStat:[{
          actionIcon: "navigate_next",
          actionText: "查看详情",          
          companyTypes: [2],
          
          color: "pink",
          icon: "biotech",
          heading: "科美",          
          items: [
            {
              title: "日成交额",
              value: "0",
            },
            {
              title: "日客户数",
              value: "0",
            },
            {
              title: "日操作人次",
              value: "0",
            },          
          ],
        },
        {
          actionIcon: "navigate_next",
          actionText: "查看详情",
          companyTypes: [1],
          color: "cyan",
          icon: "medical_services",
          heading: "医美",          
          items: [
            {
              title: "日成交额",
              value: "0",
            },
            {
              title: "日客户数",
              value: "0",
            },
            {
              title: "日操作人次",
              value: "0",
            },          
          ],
        },
        {
          actionIcon: "navigate_next",
          actionText: "查看详情",
          companyTypes: [4],
          color: "teal",
          icon: "pattern",
          heading: "私密",          
          items: [
            {
              title: "日成交额",
              value: "0",
            },
            {
              title: "日客户数",
              value: "0",
            },
            {
              title: "日操作人次",
              value: "0",
            },          
          ],
        },
        {
          actionIcon: "navigate_next",
          actionText: "查看详情",
          companyTypes: [5],
          color: "blue",
          icon: "category",
          heading: "产品",          
          items: [
            {
              title: "日成交额",
              value: "0",
            },
            {
              title: "日客户数",
              value: "0",
            },
            {
              title: "日操作人次",
              value: "0",
            },          
          ],
        },
        {
          actionIcon: "navigate_next",
          actionText: "查看详情",
          companyTypes: [6],
          color: "deep-orange",
          icon: "question_answer",
          heading: "咨询",          
          items: [
            {
              title: "日成交额",
              value: "0",
            },
            {
              title: "日客户数",
              value: "0",
            },
            {
              title: "日操作人次",
              value: "0",
            },          
          ],
        },
        {
          actionIcon: "navigate_next",
          actionText: "查看详情",
          companyTypes: [8],
          color: "pink",
          icon: "manage_accounts",
          heading: "管理",          
          items: [
            {
              title: "日成交额",
              value: "0",
            },
            {
              title: "日客户数",
              value: "0",
            },
            {
              title: "日操作人次",
              value: "0",
            },          
          ],
        },
      
      ]
    };
  },

  created() {
    this.init();
  },

  mounted() {
    //this.resize();
    window.addEventListener("resize", () => {
      this.resize();
    });
    window.addEventListener("orientationchange", () => {
      setTimeout(() => {
        this.resize();
      }, 300);
    });
  },

  methods: {
    goSale(types,start,end){
      this.$router.push({ name: "sale", 
      params: {
        companyTypes: types, 
        start: start,
        end:end
       } });
    },
    
    resize() {
      setTimeout(() => {
        const arr = this.$refs["chart"];
        arr.map((item) => {
          item.resize();
        });
      }, 10);
    },
    formatNum(value) {
      if (value) {
        return value.toLocaleString();
      } else {
        return 0;
      }
    },
    change(){
      this.init()
    },
    
    async init() {
      console.log(this.today);
      this.homeLoading = true;
      let params={}
      if(this.today){
        params={
          date: this.today,        
        }
        this.today=null
      }
      let data = await home(params);
      this.charts[0].option.xAxis.data = data.weekOrderData.x_data;
      this.charts[0].option.series[0].data = data.weekOrderData.s_data;
      this.charts[1].option.xAxis.data = data.weekTransactionData.x_data;
      this.charts[1].option.series[0].data = data.weekTransactionData.s_data;
      this.charts[2].option.xAxis.data = data.weekOprationData.x_data;
      this.charts[2].option.series[0].data = data.weekOprationData.s_data;
      this.stats[0].value =data.dailyData.turnover.toLocaleString().toString();
      this.stats[1].value = data.dailyData.customer.toString();
      this.stats[2].value = data.dailyData.operation.toString();
      this.stats[3].value =data.monthlyData.turnover.toLocaleString().toString();
      this.stats[4].value = data.monthlyData.customer.toString();
      this.stats[5].value = data.monthlyData.operation.toString();
      this.stats1[0].items[0].value = data.dailyData.turnover.toLocaleString().toString();
      this.stats1[0].items[1].value = data.dailyData.customer.toString();
      this.stats1[0].items[2].value = data.dailyData.operation.toString();
      this.stats1[0]['start']=data.today
      this.stats1[1].items[0].value = data.monthlyData.turnover.toLocaleString().toString();
      this.stats1[1].items[1].value = data.monthlyData.customer.toString();
      this.stats1[1].items[2].value = data.monthlyData.operation.toString();
      this.stats1[1]['start']=data.month

      for(let i=0;i<this.companyTypeStat.length;i++){
      this.companyTypeStat[i].items[0].value = data.dailyData.details[i].turnover.toLocaleString().toString();
      this.companyTypeStat[i].items[1].value = data.dailyData.details[i].customer.toString();
      this.companyTypeStat[i].items[2].value = data.dailyData.details[i].operation.toString();
      }  
      

      this.today = data.today
      this.week = data.week
      this.month=data.month
      this.homeLoading = false
    },
  },
};
</script>
