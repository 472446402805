import request from "@/utils/request";

export function jog(device_id,times) {
  return request({
    url: "/jog/"+device_id+"/"+times,
    method: "get",
  });
}

export function jog_interval(device_id,interval) {
  return request({
    url: "/jog_interval/"+device_id+"/"+interval,
    method: "get",
  });
}