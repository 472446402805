<template>
  <v-app>
    <TopBar value="退货统计表" :hasSearch="true" @openSearch="openSearch" />
    <SearchByCompany :show.sync="showSearch" @search="search" />
    <v-main>
      <v-container fluid>
        <v-card
          elevation="5"
          v-for="(company, index) in report"
          :key="index"
          class="mb-4"
        >
          <v-card-title
            class="text-truncate text-center"
            style="display: block"
          >
            {{ company.name }}退货统计表
          </v-card-title>
          <v-data-table
            disable-pagination
            hide-default-footer
            :headers="headers"
            :items="company.results"
            item-key="id"
            :search="company.keyword"
            :header-props="headerProps"
            :sort-desc="false"
            mobile-breakpoint="900"
            :item-class="itemRowBackground"
          >
            <template v-slot:top>
              <v-row>
                <v-col cols="9" lg="11">
                  <v-text-field
                    v-model="company.keyword"
                    label="筛选"
                    class="mx-4"
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="3"
                  lg="1"
                  class="d-flex justify-end align-center pr-6"
                >
                  <v-btn class="primary" rounded @click="exportExcel(index)">
                    <v-icon left> file_download </v-icon>导出</v-btn
                  >
                </v-col></v-row
              >
            </template>

            <template slot="body.append">
              <tr
                :class="{ 'v-data-table__mobile-table-row': isMobile }"
                class="primary--text"
              >
                <th :class="{ 'v-data-table__mobile-row': isMobile }">合计:</th>
                <th
                  :class="{ 'v-data-table__mobile-row': isMobile }"
                  v-if="!isMobile"
                ></th>
                <th
                  :class="{ 'v-data-table__mobile-row': isMobile }"
                  v-if="!isMobile"
                ></th>

                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    本期销售退货:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(company.current_total) }}
                </th>
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    前期销售本期退货:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(company.before_total) }}
                </th>
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    退货合计:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(company.amount_total) }}
                </th>
              </tr>
            </template>

            <template
              v-for="header in headers.filter((header) =>
                header.hasOwnProperty('formatter')
              )"
              v-slot:[`item.${header.value}`]="{ header, value }"
            >
              {{ header.formatter(value) }}
            </template>
          </v-data-table>
        </v-card>
      </v-container>
      <v-overlay :value="loading">
        <v-progress-circular
          indeterminate
          color="primary"
          style="
            position: absolute;
            top: 20%;
            left: 50%;
            transform: translate(-50%, -50%);
          "
          v-if="loading"
          size="70"
          :width="3"
        ></v-progress-circular>
      </v-overlay>
    </v-main>
    <BackToTop />
  </v-app>
</template>
<script>
import { returns } from "@/api/search";
export default {
  name: "ReturnView",
  data() {
    return {
      showSearch: true,
      loading: false,
      keyword: "",
      report: [],
      headerProps: {
        sortByText: "排序",
      },
      headers: [
        { text: "门店名称", value: "meirongyuan_name" },
        { text: "订单号码", value: "name" },
        { text: "顾客姓名", value: "partner_name" },
        {
          text: "本期销售退货",
          value: "current_total",
          formatter: this.formatNum,
        },
        {
          text: "前期销售本期退货",
          value: "before_total",
          formatter: this.formatNum,
        },
        { text: "退货合计", value: "amount_total", formatter: this.formatNum },
      ],
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  methods: {
    openSearch() {
      this.showSearch = !this.showSearch;
    },
    async search(condition) {
      this.loading = true;
      this.report = await returns(condition);
      this.loading = false;
      console.log(this.report);
    },
    exportExcel(index) {
      this.loading = true;
      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = [
          "门店名称",
          "订单编号",
          "顾客姓名",
          "本期销售退货",
          "前期销售本期退货",
          "退货合计",
        ];
        const filterVal = [
          "meirongyuan_name",
          "name",
          "parent_name",
          "current_total",
          "before_total",
          "amount_total",
        ];
        const list = this.report[index]["results"];
        const data = this.formatJson(filterVal, list);
        data.push([
          "合计",
          "",
          "",
          this.report[index]["current_total"],
          this.report[index]["before_total"],
          this.report[index]["amount_total"],
        ]);

        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.report[index]["name"] + "退货统计表",
          autoWidth: true,
          bookType: "xlsx",
        });
        this.loading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
    formatNum(value) {
      if (value) {
        return value.toLocaleString();
      } else {
        return 0;
      }
    },
    itemRowBackground: function (item) {
      console.log(item.meirongyuan_name)
     return item.meirongyuan_name.indexOf('小计(') >-1 ? 'style-1 primary--text' : ''
  }
  },
};
</script>

<style>
.style-1 {
  background-color: aliceblue;
}

</style>
