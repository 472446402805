<template>
  <v-dialog v-model="show" scrollable max-width="600px" persistent>
    <v-card>
      <v-card-title>
        <span class="text-h6 font-weight-light">搜索条件</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="condition">
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="condition.companyTypes"
                  :items="companyTypes"
                  :rules="companyRules"
                  item-text="name"
                  item-value="id"
                  label="选择公司类型"
                  multiple
                  small-chips
                  deletable-chips
                  chips-label="name"
                  class="font-weight-regular"
                ></v-select>
              </v-col>

              <v-col cols="12">
                <SelectDate
                  :pdate="condition.start"
                  :disabled="false"
                  label="开始日期"
                  field="start"
                  @change="dateChange"
                  :rules="dateRules"
                  ref="start"
                />
              </v-col>
              <v-col cols="12">
                <SelectDate
                  :pdate="condition.end"
                  :disabled="false"
                  label="结束日期"
                  field="end"
                  @change="dateChange"
                  :rules="dateRules"
                  ref="end"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="gray" elevation="3" rounded @click="clear"> 清 空 </v-btn>

        <v-btn color="primary" elevation="3" rounded @click="save">
          搜 索
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { company_types } from "@/api/search";
export default {
  name: "SearchByCompanyType",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      companyTypes: [],
      condition: {
        start: "",
        end: "",
        companyTypes: [],
      },
      dateRules: [(v) => !!v || "日期不能为空"],
      companyRules: [(v) => (v && v.length > 0) || "公司类型不能为空"],
    };
  },

  mounted() {
    this.getCompanyTypes();
  },
  watch: {
    show: function (val) {
      if (val) {
        setTimeout(() => {
          this.$refs.start.date = this.condition.start;
          this.$refs.end.date = this.condition.end;
        }, 100);
      }
    },
  },

  methods: {
    close() {
      this.$emit("update:show", false);
    },
    clear() {
      this.condition = {
        start: "",
        end: "",
        companyTypes: [],
      };
      this.$refs.condition.resetValidation();
    },

    save() {
      if (this.$refs.condition.validate()) {
        this.$emit("update:show", false);
        this.$emit("search", this.condition);
      }
    },
    getCompanyTypes() {
      company_types().then((res) => {
        this.companyTypes = res;
      });
    },
    dateChange(date, field) {
      this.condition[field] = date;
      if (field == "start" && this.condition.end == "") {
        this.condition.end = date;
      }
    },
  },
};
</script>
