<template>
    <v-fab-transition>
        <v-btn class="mr-3 mb-12 ml-3" small fab button bottom color="primary" fixed
            @click="$vuetify.goTo(0, goToOptions)" v-scroll="onScroll" v-show="showGoToTop"
            style="z-index: 200" :right="!$vuetify.breakpoint.mobile">
            <v-icon>expand_less</v-icon>
        </v-btn>
    </v-fab-transition>
</template>
<script>
export default {
    name: 'BackToTop',
    data: () => {
        return {
            offsetTop: 0,
            goToOptions: {
                duration: 100,
                offset: 0,
                easing: 'easeInOutCubic',
            },
        };
    },
    computed: {
        showGoToTop() {
            return this.offsetTop > 200;
        },
    },
    methods: {
        onScroll(event) {
            this.offsetTop = event.target.scrollingElement.scrollTop;
        },
    },

}
</script>
