<template>
    <v-app>
     <v-app-bar color="deep-purple" dark app>
       <v-app-bar-nav-icon>
         <v-icon>handshake</v-icon>
         </v-app-bar-nav-icon>
          
          <v-toolbar-title>扫码启动</v-toolbar-title>
          <v-spacer></v-spacer>      
       </v-app-bar>
     <v-main>
       <v-container fluid>
         
     <v-list shaped>
       <v-subheader>请选择添加方式：</v-subheader>
       <v-list-item-group color="primary"> 
         <v-list-item @click="showCamera=true">
           <v-list-item-icon>
             <v-icon color="primary">qr_code_scanner</v-icon>
           </v-list-item-icon>
           <v-list-item-content class="ml-n4">
             <v-list-item-title>扫二维码</v-list-item-title>
           </v-list-item-content>
           <v-list-item-action>
             <v-icon   
             >chevron_right</v-icon>
           </v-list-item-action>          
         </v-list-item>
         <v-divider ></v-divider>
 
         
 
         <v-list-item to="/device_switch">
           <v-list-item-icon>
             <v-icon color="primary">keyboard_hide</v-icon>
           </v-list-item-icon>
           <v-list-item-content class="ml-n4">
             <v-list-item-title>手动输入</v-list-item-title>
           </v-list-item-content>
           <v-list-item-action>
             <v-icon   
             >chevron_right</v-icon>
           </v-list-item-action>          
         </v-list-item>
         <v-divider ></v-divider>
         
         
       </v-list-item-group>
     </v-list>
 
 
     <v-dialog
       v-model="showCamera"
       width="500"
     >     
 
       <v-card :loading="!loadStream"  >
         <v-card-title class="text-h6 grey lighten-2">
           开始扫码
         </v-card-title>
 
         <v-card-text class="pa-2">
           <StreamQrcodeReaderVue :showCamera="showCamera" @decode="onDecode" @loaded="onLoaded" ref="StreamQrcode"/>
         </v-card-text>
 
         <v-divider></v-divider>
 
         <v-card-actions>
           <v-spacer></v-spacer>
           <v-btn
             color="primary"
             text
             @click="showCamera = false"
           >
             Close
           </v-btn>
         </v-card-actions>
       </v-card>
     </v-dialog>
     
 
       </v-container>
     </v-main>
     
    </v-app>
 </template>
 
 <script>
   
   import StreamQrcodeReaderVue from '@/components/StreamQrcodeReader.vue'
   
 
   export default {
     name: 'DeviceTest',
 
     components: {           
     
       StreamQrcodeReaderVue,
     },
 
     data: () => ({
       code: null, 
       showCamera: false,
       loadStream: false,     
     }),
 
    beforeRouteLeave (to, from, next) {    
     if(this.$refs.StreamQrcode!=undefined){
       this.$refs.StreamQrcode.codeReader.reset()
     }    
     next()
    },
 
     watch: {
       showCamera: function(val) {
         if (!val) {
           this.loadStream = false
         }
       },
     },
     
     
     methods: {
    
     
     add(code){
       this.$router.push('/device_switch?code='+code)
     },
     onDecode (result) 
        { 
         console.log(result) 
         this.showCamera=false
         this.$router.push('/device_switch?code='+result)
        },
       onLoaded()
       {
        console.log('loaded')
        this.loadStream=true
       },
     }
 
   
   }
 </script>
 
 