<template>
  <material-card
    class="v-card--material-stats"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #heading>
      <div>1212</div>
    </template>
    <template #subtitle>
      <div
        class="text-right text-h5 font-weight-light v-card__subtitle--material-stats"
      >
        <div class="d-flex flex-no-wrap justify-space-around">
          <template v-for="item in items">
            <div>
              <v-card-title
                class="text-center justify-center font-weight-light"
                :class="
                  $vuetify.breakpoint.smAndDown ? 'text-subtitle-1' : 'text-h6'
                "
                >{{ item.value }}</v-card-title
              >

              <v-card-subtitle
                class="text-center text-subtitle-2 font-weight-light v-card--material__title"
                >{{ item.title }}</v-card-subtitle
              >
            </div>
          </template>
        </div>
      </div>
    </template>

    <template #actions>
      <slot name="actions" />
    </template>

    <slot />
  </material-card>
</template>

<script>
export default {
  name: "MaterialStatCardThree",

  props: { items: { type: Array, default: () => [] } },
};
</script>

<style lang="sass">
.v-card--material-stats.v-card--material .v-card--material__title
  color: #999999
  font-size: .875rem !important
  margin-left: auto
  text-align: right

.v-card__subtitle--material-stats
  color: #3C4858
</style>
