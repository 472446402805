<template>
  <v-dialog v-model="show" scrollable max-width="600px" persistent>
    <v-card>
      <v-card-title>
        <span class="text-h6 font-weight-light">搜索条件</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="condition">
            <v-row>
              <v-col cols="12">
                <!-- <v-autocomplete
                v-model="condition.companys"
                :items="companys"
                dense
                chips
                small-chips
                deletable-chips
                label="选择公司"
                multiple
                return-object 
                :rules="companyRules" 
                item-text="name" 
                item-value="id"
                >
              </v-autocomplete> -->

                <v-select
                  v-model="condition.companys"
                  :items="companys"
                  :rules="companyRules"
                  item-text="name"
                  item-value="id"
                  label="选择公司"
                  multiple
                  small-chips
                  deletable-chips
                  return-object
                  chips-label="name"
                  class="font-weight-regular"
                >
                  <template v-slot:prepend-item>
                    <v-list-item ripple @mousedown.prevent @click="selectAll">
                      <v-list-item-action>
                        <v-icon
                          :color="
                            condition.companys.length > 0 ? 'indigo darken-4' : ''
                          "
                        >
                          {{ icon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>全选</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12">
                <SelectDate
                  :pdate="condition.start"
                  :disabled="false"
                  label="开始日期"
                  field="start"
                  @change="dateChange"
                  :rules="dateRules"
                />
              </v-col>
              <v-col cols="12">
                <SelectDate
                  :pdate="condition.end"
                  :disabled="false"
                  label="结束日期"
                  field="end"
                  @change="dateChange"
                  :rules="dateRules"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="gray" elevation="3" rounded @click="clear"> 清 空 </v-btn>

        <v-btn color="primary" elevation="3" rounded @click="save">
          搜 索
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { company } from "@/api/search";
export default {
  name: "SearchByCompany",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      companys: [],
      condition: {
        start: "",
        end: "",
        companys: [],
      },
      dateRules: [(v) => !!v || "日期不能为空"],
      companyRules: [(v) => (v && v.length > 0) || "公司不能为空"],
    };
  },

  computed: {
    icon() {
      return this.condition.companys.length ==this.companys.length ? "check_box" : "check_box_outline_blank";
    },
  },
 
  mounted() {
    this.getCompanys();
  },

  methods: {
    close() {
      this.$emit("update:show", false);
    },
    clear() {
      this.condition = {
        start: "",
        end: "",
        companys: [],
      };
      this.$refs.condition.resetValidation();
    },

    save() {
      if (this.$refs.condition.validate()) {
        this.$emit("update:show", false);
        this.$emit("search", this.condition);
      }
    },
    getCompanys() {
      company().then((res) => {
        this.companys = res;
        console.log(this.companys);
      });
    },
    dateChange(date, field) {
      this.condition[field] = date;
      if (field == "start" && this.condition.end == "") {
        this.condition.end = date;
      }
    },
    selectAll() {
      if (this.condition.companys.length) {
        this.condition.companys = [];
      } else {
        this.condition.companys = this.companys;
      }
    },
  },
};
</script>
