<template>
    <v-app id="inspire">
       <v-content>
          <v-container fluid fill-height>
 
             <v-layout align-center justify-center>
 
                <v-flex xs12 sm8 md4>
                   <v-img :src="require('../assets/logo.png')" contain height="80" class="mb-16 mt-n16" />
                   <v-card class="elevation-12 ml-3 mr-3 font-weight-light" shaped>
                      <v-toolbar dark color="primary">
                         <v-toolbar-title>用户登录</v-toolbar-title>
                      </v-toolbar>
                      <v-form ref="form" @submit.prevent="handleLogin" >
                      <v-card-text>
                         
                            <v-text-field prepend-icon="person" name="name" label="用户名" type="text"
                               v-model.trim="form.name" :rules="rules.name"></v-text-field>
                            <v-text-field id="password" prepend-icon="lock" name="password" label="密码" type="password"
                               v-model.trim="form.password" :rules="rules.password"></v-text-field>
                         
                      </v-card-text>
                      <v-card-actions>
                         <v-spacer></v-spacer>
                         <v-btn :loading="loading" color="primary" elevation="3" rounded  type="submit">登 录</v-btn>
                      </v-card-actions>
                     </v-form>
                   </v-card>
                </v-flex>
             </v-layout>
          </v-container>
       </v-content>
    </v-app>
 </template>
 
 <script>
 import { login } from '@/api/user.js'
 export default {
    name: 'Login',
    props: {
       source: String,
    },
    data() {
       return {
          form: {
             name: '',
             password: '',
          },
          rules: {
             name: [
                v => !!v || '用户名不能为空',
                v => (v && v.length > 2) || '用户名长度不能小于2位',
             ],
             password: [
                v => !!v || '密码不能为空',
                v => (v && v.length > 0) || '密码长度不能小于1位',
             ],
          },
          loading: false,
 
       }
    },
    methods: {
       async handleLogin() {
          if (this.$refs.form.validate()) {
             let data={}
             this.loading=true
             data=await login(this.form).finally(()=>{
                this.loading=false
             })            
             if(data){
                //console.log(data)
                let token=data.access_token
                if(token){
                   localStorage.setItem('token',token)
                   this.$router.replace('/')
                }
             }
             // try {
             //    await login(this.form).then(res => {
             //       console.log(res)
             //       let token=res.session.sid
             //       if(token){
             //          localStorage.setItem('token',token)
             //          this.$router.push('/')
             //       }
             //    })
             // } finally {
             //    this.loading=false
             // }
 
          }
 
       },
    },
 };
 </script>
 
 <style>
 </style>
 