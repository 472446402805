import Vue from 'vue';
import Vuetify, { VSnackbar, VBtn, VIcon } from 'vuetify/lib'
import '@fortawesome/fontawesome-free/css/all.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import colors from 'vuetify/lib/util/colors'
import VuetifyToast from 'vuetify-toast-snackbar'
import JsonExcel from "vue-json-excel"


const requireComponent = require.context('@/components', true, /\.vue$/)

for (const file of requireComponent.keys()) {
  const componentConfig = requireComponent(file)

  Vue.component(
    componentConfig.default.name,
    componentConfig.default || componentConfig,
  )
}
Vue.component("downloadExcel", JsonExcel)

Vue.use(Vuetify, {
    components: {
        VSnackbar,
        VBtn,
        VIcon
      }
});

Vue.use(VuetifyToast);

export default new Vuetify(
    {   
        icons:{
            iconfont: 'md' || 'fa' || 'mdi'
        },
        theme: {
            themes: {
              light: {
                primary: colors.deepPurple,
                secondary: colors.grey.darken1,
                accent: colors.shades.black,
                error: colors.red.accent3,
              },
              dark: {
                primary: colors.blue.lighten3,
              },
            },
          },
     }
);

