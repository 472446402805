import request from "@/utils/request";

export function company() {
  return request({
    url: "/companys",
    method: "get",
  });
}
export function company_types() {
  return request({
    url: "/company_types",
    method: "get",
  });
}

export function home(params) {
  return request({
    url: "/home",
    method: "get",
    params,
  });
}

export function profit(data) {
  return request({
    url: "/profit",
    method: "post",
    data,
  });
}

export function profit_shop(data) {
  return request({
    url: "/profit_shop",
    method: "post",
    data,
  });
}

export function activity(data) {
  return request({
    url: "/activity",
    method: "post",
    data,
  });
}

export function company_promit(data) {
  return request({
    url: "/company_promit",
    method: "post",
    data,
  });
}

export function returns(data) {
  return request({
    url: "/return",
    method: "post",
    data,
  });
}

export function sale(data) {
  return request({
    url: "/sale",
    method: "post",
    data,
  });
}