<template>
  <v-app>
    <TopBar value="收入统计表" :hasSearch="true" @openSearch="openSearch" />
    <SearchByCompany :show.sync="showSearch" @search="search" />
    <v-main>
      <v-container fluid>
        <v-card
          elevation="5"
          v-for="(company, index) in report"
          :key="index"
          class="mb-4"
        >
          <v-card-title
            class="text-truncate text-center"
            style="display: block"
          >
            {{ company.name }}收入统计表(行程)
          </v-card-title>
          <v-data-table
            disable-pagination
            hide-default-footer
            :headers="headers"
            :items="company.batchs"
            item-key="id"
            :search="company.keyword"
            :header-props="headerProps"
            sort-by="amount_total"
            :sort-desc="true"
            mobile-breakpoint="900"
          >
            <template v-slot:top>
              <v-row>
                <v-col cols="9" lg="11">
                  <v-text-field
                    v-model="company.keyword"
                    label="筛选"
                    class="mx-4"
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="3"
                  lg="1"
                  class="d-flex justify-end align-center pr-6"
                >
                  <v-btn class="primary" rounded @click="exportExcel(index)">
                    <v-icon left> file_download </v-icon>导出</v-btn
                  >
                </v-col></v-row
              >
            </template>

            <template slot="body.append">
              <tr
                :class="{ 'v-data-table__mobile-table-row': isMobile }"
                class="primary--text"
              >
                <th :class="{ 'v-data-table__mobile-row': isMobile }">合计:</th>
                <th
                  :class="{ 'v-data-table__mobile-row': isMobile }"
                  v-if="!isMobile"
                ></th>
                <th
                  :class="{ 'v-data-table__mobile-row': isMobile }"
                  v-if="!isMobile"
                ></th>
                <th
                  :class="{ 'v-data-table__mobile-row': isMobile }"
                  v-if="!isMobile"
                ></th>
                <th
                  :class="{ 'v-data-table__mobile-row': isMobile }"
                  v-if="!isMobile"
                ></th>
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    项目收入:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(company.p_income) }}
                </th>
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    升单收入:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(company.upgrade) }}
                </th>
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    卡项:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(company.kaxiang) }}
                </th>
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    特购:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(company.tegou) }}
                </th>
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    销售退货:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(company.return) }}
                </th>
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    收入合计:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(company.total) }}
                </th>
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    收入占比(%):
                    <v-spacer></v-spacer>
                  </template>
                  100%
                </th>
                <th
                  :class="{ 'v-data-table__mobile-row': isMobile }"
                  v-if="!isMobile"
                ></th>

                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    见诊人数:
                    <v-spacer></v-spacer>
                  </template>
                  {{ company.all_person }}
                </th>
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    成交人数:
                    <v-spacer></v-spacer>
                  </template>
                  {{ company.count }}
                </th>
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    成交率:
                    <v-spacer></v-spacer>
                  </template>
                  {{ company.sale_rate }}
                </th>
              </tr>
            </template>

            <template
              v-for="header in headers.filter((header) =>
                header.hasOwnProperty('formatter')
              )"
              v-slot:[`item.${header.value}`]="{ header, value }"
            >
              {{ header.formatter(value) }}
            </template>
          </v-data-table>
        </v-card>
      </v-container>
      <v-overlay :value="loading">
        <v-progress-circular
          indeterminate
          color="primary"
          style="
            position: absolute;
            top: 20%;
            left: 50%;
            transform: translate(-50%, -50%);
          "
          v-if="loading"
          size="70"
          :width="3"
        ></v-progress-circular>
      </v-overlay>
    </v-main>
    <BackToTop />
  </v-app>
</template>
<script>
import { profit } from "@/api/search";
export default {
  name: "ProfitView",
  data() {
    return {
      showSearch: true,
      loading: false,
      keyword: "",
      report: [],
      headerProps: {
        sortByText: "排序",
      },
      headers: [
        { text: "行程", value: "name" },
        { text: "开始日期", value: "date" },
        { text: "结束日期", value: "date_end" },
        { text: "门店名称", value: "meirongyuan_name" },
        { text: "销售小组", value: "mc_team_name" },
        { text: "项目收入", value: "p_income", formatter: this.formatNum },
        { text: "升单收入", value: "upgrade", formatter: this.formatNum },
        { text: "卡项", value: "kaxiang", formatter: this.formatNum },
        { text: "特购", value: "tegou", formatter: this.formatNum },
        { text: "销售退货", value: "return", formatter: this.formatNum },
        { text: "收入合计", value: "amount_total", formatter: this.formatNum },
        { text: "收入占比(%)", value: "scale" },
        { text: "客单价", value: "average", formatter: this.formatNum },
        { text: "见诊人数", value: "all_person" },
        { text: "成交人数", value: "count" },
        { text: "成交率", value: "sale_rate" },
      ],
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  methods: {
    openSearch() {
      this.showSearch = !this.showSearch;
    },
    async search(condition) {
      this.loading = true;
      this.report = await profit(condition);
      this.loading = false;
      console.log(this.report);
    },
    exportExcel(index) {
      this.loading = true;
      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = [
          "行程",
          "开始日期",
          "结束日期",
          "门店名称",
          "销售小组",
          "项目收入",
          "升单收入",
          "卡项",
          "特购",
          "销售退货",
          "收入合计",
          "收入占比",
          "客单价",
          "见诊人数",
          "成交人数",
          "成交率",
        ];
        const filterVal = [
          "name",
          "date",
          "date_end",
          "meirongyuan_name",
          "mc_team_name",
          "p_income",
          "upgrade",
          "kaxiang",
          "tegou",
          "return",
          "amount_total",
          "scale",
          "average",
          "all_person",
          "count",
          "sale_rate",
        ];
        const list = this.report[index]["batchs"];
        const data = this.formatJson(filterVal, list);
        data.push([
          "合计",
          "",
          "",
          "",
          "",
          this.report[index]["p_income"],
          this.report[index]["upgrade"],
          this.report[index]["kaxiang"],
          this.report[index]["tegou"],
          this.report[index]["return"],
          this.report[index]["total"],
          "100%",
          "",
          this.report[index]["all_person"],
          this.report[index]["count"],
          this.report[index]["sale_rate"],
        ]);

        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.report[index]["name"] + "收入统计表(行程)",
          autoWidth: true,
          bookType: "xlsx",
        });
        this.loading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
    formatNum(value) {
      if (value) {
        return value.toLocaleString();
      } else {
        return 0;
      }
    },
  },
};
</script>
