<template>
    <v-app>
      <v-app-bar color="primary" dark app>
        <v-app-bar-nav-icon to="/device_test">
          <v-icon>chevron_left</v-icon>
        </v-app-bar-nav-icon>
        <v-toolbar-title>启动设备</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-app-bar>
      <v-main>
        <v-container fluid>
          <v-card elevation="4" outlined class="ma-4 pb-8">
            <v-card-text>
              <v-form ref="form">                
                <v-text-field v-model="form.device_id"  label="设备编码" :rules="rules.device_id" ></v-text-field> 
                <v-text-field v-model="form.times" :disabled="true" label="点动次数"></v-text-field>                     
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn  color="primary" elevation="3" rounded @click="handleSubmit('1')">点动1次</v-btn>
              <v-btn  color="primary" elevation="3" rounded @click="handleSubmit('2')">点动2次</v-btn>
              <v-btn  color="primary" elevation="3" rounded @click="handleSubmit('3')">点动3次</v-btn>
            </v-card-actions>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn  color="primary" elevation="3" rounded @click="handleInterval('1')">点动间隔1秒</v-btn>
              <v-btn  color="primary" elevation="3" rounded @click="handleInterval('2')">点动间隔2秒</v-btn>
              <v-btn  color="primary" elevation="3" rounded @click="handleInterval('3')">点动间隔3秒</v-btn>
            </v-card-actions>
          </v-card>
  
          <v-dialog v-model="dialog" persistent max-width="290">
  
            <v-card>
              <v-card-title class="text-h6">
                确认要提交吗?
              </v-card-title>
  
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn x-large color="primary darken-1" text @click="dialog = false">
                  取消
                </v-btn>
                <v-btn x-large color="primary darken-1" text @click="jog">
                  确认
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-overlay :value="loading">
        <v-progress-circular
          indeterminate
          color="primary"
          style="
            position: absolute;
            top: 20%;
            left: 50%;
            transform: translate(-50%, -50%);
          "
          v-if="loading"
          size="70"
          :width="3"
        ></v-progress-circular>
      </v-overlay>
        </v-container>
      </v-main>
    </v-app>
  </template>
  <script>
 
 
 
  import {jog,jog_interval} from '@/api/device'
  export default {
    components: {
     
    },
    data: () => ({
      dialog: false,
      code: '',
      disabled: false,
      loading: false,
      flag:0,
      form: {
        'device_id': null,
        'times': 1,
        'interval': 1,            
      },    
      rules: {
        device_id: [
          v => !!v || '请选择设备',
        ],        
      },
  
  }),  
    created() {
      const route = this.$route
      this.code = route.query.code
      if (this.code) {
        this.disabled = true
        this.form.device_id = this.code
      }
      
  
    },
   
    methods: {
      
      handleSubmit(times) {
        if (this.$refs.form.validate()) {
          this.flag=0
          console.log(this.form)
          this.form.times = times
          
          this.dialog = true
        }
      },
      handleInterval(interval) {
        if (this.$refs.form.validate()) {
         this.flag=1
          console.log(this.form)
          this.form.interval = interval
          
          this.dialog = true
        }
      },
      async jog() {
        this.dialog = false
        this.loading = true
        let res 
        
        if(this.flag==0){
          res = await jog(this.form.device_id,this.form.times)
        }else{
          res = await jog_interval(this.form.device_id,this.form.interval)
        }
        
        if (res) {
            this.$toast('操作成功')
          console.log(res)
        }
        this.loading = false
      }, 
      
     
         
      
    },
  
  }
  </script>
  
  <style>
  .input-group--disabled.checkbox .input-group__input {
    color: #000 !important;
  }
  
  .theme--light.v-input--is-disabled input {
    color: rgba(0, 0, 0, 0.6) !important;
  }
  </style>