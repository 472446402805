<template>
  <v-app>
    <TopBar value="销售统计表" :hasSearch="true" @openSearch="openSearch" />
    <SearchByCompanyType
      :show.sync="showSearch"
      @search="search"
      ref="search"
    />
    <v-main>
      <v-container fluid>
        <v-card elevation="5" class="mb-4">
          <v-card-title
            class="text-truncate text-center"
            style="display: block"
          >
            销售统计表
          </v-card-title>
          <v-data-table
            disable-pagination
            hide-default-footer
            :headers="headers"
            :items="report.data"
            item-key="id"
            :search="keyword"
            :header-props="headerProps"
            sort-by="turnover"
            :sort-desc="true"
          >
            <template v-slot:top>
              <v-row>
                <v-col cols="9" lg="11">
                  <v-text-field
                    v-model="keyword"
                    label="筛选"
                    class="mx-4"
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="3"
                  lg="1"
                  class="d-flex justify-end align-center pr-6"
                >
                  <v-btn class="primary" rounded @click="exportExcel()">
                    <v-icon left> file_download </v-icon>导出</v-btn
                  >
                </v-col></v-row
              >
            </template>

            <template slot="body.append">
              <tr
                :class="{ 'v-data-table__mobile-table-row': isMobile }"
                class="primary--text"
              >
                <th :class="{ 'v-data-table__mobile-row': isMobile }">合计:</th>
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    订单业绩:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(report.turnover) }}
                </th>
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    实收业绩:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(report.real_income) }}
                </th>
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    本期应收:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(report.current_not_received) }}
                </th>
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    累计应收:
                    <v-spacer></v-spacer>
                  </template>
                  {{ formatNum(report.acc_not_received) }}
                </th>
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    客户数:
                    <v-spacer></v-spacer>
                  </template>
                  {{ report.customer }}
                </th>
                <th :class="{ 'v-data-table__mobile-row': isMobile }">
                  <template v-if="isMobile">
                    操作人次:
                    <v-spacer></v-spacer>
                  </template>
                  {{ report.operation }}
                </th>
              </tr>
            </template>

            <template
              v-for="header in headers.filter((header) =>
                header.hasOwnProperty('formatter')
              )"
              v-slot:[`item.${header.value}`]="{ header, value }"
            >
              {{ header.formatter(value) }}
            </template>
          </v-data-table>
        </v-card>
      </v-container>
      <v-overlay :value="loading">
        <v-progress-circular
          indeterminate
          color="primary"
          style="
            position: absolute;
            top: 20%;
            left: 50%;
            transform: translate(-50%, -50%);
          "
          v-if="loading"
          size="70"
          :width="3"
        ></v-progress-circular>
      </v-overlay>
    </v-main>
    <BackToTop />
  </v-app>
</template>
<script>
import { sale } from "@/api/search";

export default {
  name: "SaleView",
  data() {
    return {
      showSearch: true,
      loading: false,
      keyword: "",
      report: [],
      headerProps: {
        sortByText: "排序",
      },
      headers: [
        { text: "公司名", value: "name" },
        { text: "订单业绩", value: "turnover", formatter: this.formatNum },
        { text: "实收业绩", value: "real_income", formatter: this.formatNum },
        { text: "本期应收", value: "current_not_received", formatter: this.formatNum },
        { text: "累计应收", value: "acc_not_received", formatter: this.formatNum },
        { text: "客户数", value: "customer" },
        { text: "操作人次", value: "operation" },
      ],
    };
  },
  created() {
    if (JSON.stringify(this.$route.params) != "{}") {
      this.showSearch = false;
      this.search(this.$route.params);
      setTimeout(() => {
        this.$refs.search.condition = this.$route.params;
      }, 100);
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },
  },
  methods: {
    openSearch() {
      this.showSearch = !this.showSearch;
    },
    async search(condition) {
      this.loading = true;
      this.report = await sale(condition);
      this.loading = false;
    },
    exportExcel() {
      this.loading = true;
      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = ["公司名", "订单业绩", "实收业绩","本期应收","累计应收","客户数", "操作人次"];
        const filterVal = ["name", "turnover", "customer", "operation"];
        const list = this.report["data"];
        const data = this.formatJson(filterVal, list);
        data.push([
          "合计",
          this.report["turnover"],
          this.report["real_income"],
          this.report["current_not_received"],
          this.report["acc_not_received"],
          this.report["customer"],
          this.report["operation"],
        ]);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "销售统计表",
          autoWidth: true,
          bookType: "xlsx",
        });
        this.loading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
    formatNum(value) {
      if (value) {
        return value.toLocaleString();
      } else {
        return 0;
      }
    },
  },
};
</script>
