<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    :offset-y="$vuetify.breakpoint.xs ? false : true"
    :offset-x="$vuetify.breakpoint.xs ? true : false"
    :left="$vuetify.breakpoint.xs ? true : false"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }" >
      <v-text-field
        v-model="date"
        :label="label"
        readonly
        v-bind="attrs"
        v-on="on"
        :disabled="disabled"
        :rules="rules"
        class="font-weight-regular"
      />
    </template>
    <v-date-picker
      v-model="date"
      @input="menu = false"
      @change="change"
      locale="zh-cn"
      show-adjacent-months
    ></v-date-picker>
  </v-menu>
</template>
<script>
export default {
  name: "SelectDate",
  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    pdate: {
      type: [String],
      
    },
    field: {
      type: String,
      required: true,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      menu: false,
      date: null,      
    };
  },
  watch: {
    pdate: function (val) {
      if (this.pdate) {
        this.date = val;
      } else {
        this.date = "";
      }
    },
  },
  methods: {
    change() {
      this.$emit("change", this.date, this.field);
    },
  },
};
</script>
